import styled, { css } from "styled-components";

export const IconBlockOffsetGridWrapper = styled.div`
  font-family: 'Roboto';
  background-image: ${props => `url(${props.backgroundImage?.primaryImage?.file?.url})`};
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 1170px) {
    background-size: cover;
  }
`

export const OffsetGridContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 96px 0px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 96px 60px;
  }
  @media (max-width: 992px) {
    padding: 80px 35px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 576px) {
    padding: 64px 35px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
  padding-top: ${noTopPadding === true && 0};
  padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const HeadingContainer = styled.div`
  max-width: 486px;
  @media (max-width: 992px) {
    max-width: 776px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Kicker = styled.span`
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #0022B7;
`

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  color: #101828;
  margin: 24px 0 0;
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (max-width: 576px) {
    font-size: 32px;
  }
`

export const Subheading = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #475467;
  margin: 16px 0 0;
  @media (max-width: 992px) {
    max-width: 570px;
    text-align: center;
  }
  @media(max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const GridContainer = styled.div`
  max-width: 602px;
  display: flex;
  flex-wrap: wrap;
  margin: -16px 0;
  padding-left: 60px;
  @media (max-width: 992px) {
    padding-left: 0px;
    margin: 32px 0 0;
  }
`

export const GridItem = styled.div`
  width: 50%;
  padding: 16px;
  @media (max-width: 576px) {
    width: 100%;
    padding: 16px 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
`

export const GridItemWrapper = styled.div`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const GridItemIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
`

export const GridItemTitle = styled.h5`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #101828;
  margin: 16px 0 0;
`

export const GridItemDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin: 8px 0 0;
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (max-width: 576px) {
    max-width: 375px;
  }
`

export const CTA = styled.a`
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  color: #0022B7;
  margin: 32px 0 0;
`