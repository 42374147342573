import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {
  IconBlockOffsetGridWrapper,
  OffsetGridContainer,
  HeadingContainer,
  Kicker,
  Heading,
  Subheading,
  GridContainer,
  GridItem,
  GridItemWrapper,
  GridItemIcon,
  GridItemTitle,
  GridItemDescription,
  CTA,
} from "./styles/OffsetGrid.styled.js"

const IconBlockOffsetGrid = ({ component }) => {
  const {
    heading,
    kicker,
    subheading,
    description,
    callsToAction,
    icons,
    backgroundImage,
    noTopPadding,
    noBottomPadding,
  } = component

  return (
    <IconBlockOffsetGridWrapper backgroundImage={backgroundImage}>
      <OffsetGridContainer
        noTopPadding={noTopPadding}
        noBottomPadding={noBottomPadding}
      >
        <HeadingContainer>
          {kicker && <Kicker>{kicker}</Kicker>}
          <Heading>{heading}</Heading>
          {subheading?.subheading && (
            <Subheading>{subheading?.subheading}</Subheading>
          )}
          {/* <CTA href={callsToAction.internalLink || callsToAction.link}>{callsToAction.label}</CTA> */}
        </HeadingContainer>
        <GridContainer>
          {icons?.map((icon, index) => (
            <GridItem key={index}>
              <GridItemWrapper>
                <GridItemIcon>
                  <img
                    src={icon?.primaryImage?.file?.url}
                    alt={icon?.primaryImage?.description || "icon"}
                  />
                </GridItemIcon>
                <GridItemTitle>{icon?.title}</GridItemTitle>
                {icon?.caption && (
                  <GridItemDescription>
                    {documentToReactComponents(JSON.parse(icon?.caption?.raw))}
                  </GridItemDescription>
                )}
              </GridItemWrapper>
            </GridItem>
          ))}
        </GridContainer>
      </OffsetGridContainer>
    </IconBlockOffsetGridWrapper>
  )
}

export default IconBlockOffsetGrid
